import choozy from '../../_snowpack/pkg/choozy.js'

class recipeContent {
    constructor(el) {
        this.el = el
        this.dom = choozy(el)

        this.el.classList.add('js-overflow')
        this.dom.reveal.addEventListener('click', () => this.reveal())
    }

    reveal() {
        this.el.classList.remove('js-overflow')
    }
}
    
document.querySelectorAll('[data-component="recipeContent"]').forEach((el) => {
    new recipeContent(el)
})