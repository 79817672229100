import { createFocusTrap } from '../../_snowpack/pkg/focus-trap.js'
import choozy from '../../_snowpack/pkg/choozy.js'

const CLASSES = {
  open: 'is-menu-active'
}

class Gallery extends HTMLElement {
  connectedCallback() {
    this.dom = choozy(this)

    for (let thumb of this.dom.thumb) {
      thumb.addEventListener('click', () => this.enableThumb(thumb))
    }
  }

  enableThumb(clickedThumb) {
    this.dom.main.src = clickedThumb.src 

    for (let thumb of this.dom.thumb) {
        thumb.classList.remove('is-active')
    }

    clickedThumb.classList.add('is-active')
  }
}
    
customElements.define('product-gallery', Gallery);