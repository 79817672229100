import choozy from '../../_snowpack/pkg/choozy.js'

class recipeTabs {
    constructor(el) {
        this.el = el
        this.dom = choozy(el)

        this.dom.ingredientLabel.addEventListener('click', (e) => this.enableTab('ingredient', e))
        this.dom.methodLabel.addEventListener('click', (e) => this.enableTab('method', e))
    }

    enableTab(tab, e) {
        e.preventDefault()
        this.dom[`${tab}Check`].checked = true
        
        if (this.el.getBoundingClientRect().top < 10) {
            this.el.scrollIntoView()
        }
    }
}
    
document.querySelectorAll('[data-component="recipeTabs"]').forEach((el) => {
    new recipeTabs(el)
})