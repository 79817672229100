import { createFocusTrap } from '../../_snowpack/pkg/focus-trap.js'
import choozy from '../../_snowpack/pkg/choozy.js'

const CLASSES = {
  open: 'is-menu-active'
}

class MegaNav extends HTMLElement {
  connectedCallback() {
    let dom = choozy()

    this.isOpen = false
    this.focusTrap = createFocusTrap(this)

    let toggles = Array.isArray(dom.meganavToggle) ? dom.meganavToggle : [dom.meganavToggle]

    for (let toggle of toggles) {
      toggle.addEventListener('click', () => this.toggleMenu(toggle))
    }
  }

  toggleMenu(toggle) {
    this.isOpen = !this.isOpen
    document.body.classList.toggle(CLASSES.open)

    toggle.setAttribute('aria-expanded', String(this.isOpen))

    if (this.isOpen) {
      //this.focusTrap.activate()
    } else {
      //this.focusTrap.deactivate()
    }
  }
}
    
customElements.define('mega-nav', MegaNav);