import choozy from '../../_snowpack/pkg/choozy.js'

const CLASSES = {

}

class Details {
    constructor(el) {
        this.el = el
        this.details = el.querySelectorAll('details')
        
        // Listen for open or close
        for (let details of this.details) {
            details.addEventListener('toggle', () => this.toggle(details))
        }
    }

    toggle(el) {
        // If summary is at the top of the viewport, snap it into view on open/close
        if (el.getBoundingClientRect().top < 10) {
            el.scrollIntoView()
        }        
    }
}
    
document.querySelectorAll('[ne-details-group]').forEach((el) => {
    new Details(el)
})