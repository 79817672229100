import choozy from '../../_snowpack/pkg/choozy.js'

const CLASSES = {

}

class scrollToTop {
    constructor(el) {
        this.el = el
        this.el.addEventListener('click', () => window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        }))
    }
}
    
document.querySelectorAll('[data-component="scrollToTop"]').forEach((el) => {
    new scrollToTop(el)
})