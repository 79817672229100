import choozy from '../../_snowpack/pkg/choozy.js'

const CLASSES = {
  open: 'is-open'
}

class PromoBanner extends HTMLElement {
  connectedCallback() {
    let dom = choozy()

    dom.open.addEventListener('click', () => this.open())
    dom.close.addEventListener('click', () => this.close())
  }

  open() {
      this.classList.toggle(CLASSES.open)
  }

  close() {
     this.classList.add('dn')
  }
}
    
customElements.define('promo-banner', PromoBanner);